//#region Constants
const PKG_FULLDAY_OUTING = { name: "Full Day Package", price: 12000, limit: 6 };
const PKG_MORNING_OUTING = { name: "Morning Package", price: 9000, limit: 6 };
const PKG_EVENING_OUTING = { name: "Evening Package", price: 9000, limit: 6 };
const PKG_PHOTO_VIDEO_SHOOT = { name: "Photo & Video Shoot", price: 15000, limit: 10 };
const PKG_PARTY_EVENT = { name: "Party & Events", price: 30000, limit: 20 };
const PKG_MARRAIGE_RECEPTION = { name: "Marriage & Reception", price: 50000, limit: 0 };

const PKG_FULLDAY_ADDITIONAL_VISITOR = { name: "ADDITIONAL VISITOR", price: 1500, limit: 0 };
const PKG_HALFDAY_ADDITIONAL_VISITOR = { name: "ADDITIONAL VISITOR", price: 1000, limit: 0 };

const SCHEDULE_FULLDAY = "FULLDAY";
const SCHEDULE_HALFDAY = "HALFDAY";
// const COUPON_CODE_1 = "AVRWEB";
// const COUPON_CODE_1_VAL = 10;


const COUPON_CODES = [
    { name: "AVRWEB5", value: 5, type: "P" },
    { name: "AVRWEB10", value: 10, type: "P" },
    { name: "AVR500", value: 500, type: "F" },
    { name: "AVR1000", value: 1000, type: "F" }
];
//#endregion

var navLinks = document.getElementById('navLinks');

function showMenu(isShow) {
    if (isShow)
        navLinks.style.right = "0";
    else
        navLinks.style.right = "-200px";
};

//Changes on 17/12/2021 13-22
function loadMenuBar(){
    var menubtn = document.getElementsByClassName("menu");
    if(menubtn && menubtn.length > 0){
        Array.prototype.forEach.call(menubtn, menu => {
            menu.addEventListener("click", function (e) {
                if (e.srcElement.className == "fa fa-bars menu")
                    navLinks.style.right = "0px";
                else
                    navLinks.style.right = "-200px";
            });
        });
    }

    document.addEventListener('click', function(event) {
        //var isClickInsideElement = navLinks.contains(event.target);
        if (navLinks.style.right == "0px" && event.srcElement.className != "fa fa-bars menu") {
            //Do something click is outside specified element
            navLinks.style.right = "-200px";
        }
    });
};

function loadScroller(){
    // Scroll To Top
    var btnScroll = document.getElementById('btnScroll');
    if(btnScroll){
        btnScroll.addEventListener("click", function () {
            // window.scrollTo(0,0);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        });
    }
};

// Get the modal
var modal = document.getElementById("myModal");
// Get the image and insert it inside the modal - use its "alt" text as a caption
//var img = document.getElementById("myImg");
var modalImg = document.getElementById("img01");
var captionText = document.getElementById("caption");
function showModal(id) {
    var img = document.getElementById(id);
    modal.style.display = "block";
    modalImg.src = img.src;
    captionText.innerHTML = img.alt;
}

function loadModalImage(){
//Changes on 17/12/2021 for ShowModal func error
    var images = document.getElementsByClassName("modalShow");
    if(images && images.length > 0){
        Array.prototype.forEach.call(images, image => {
            if (image.id !== "logo") {
                image.addEventListener("click", function (e) {
                    document.getElementById("myModal").style.display = "block";
                    document.getElementById("img01").src = e.srcElement.src;
                    document.getElementById("caption").innerHTML = e.srcElement.alt;
                    // modal.style.display = "block";
                    // modalImg.src = e.srcElement.src;
                    // captionText.innerHTML = e.srcElement.alt;
                });
            }
        });
    }

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    if (span) {
        span.onclick = function () {
            modal.style.display = "none";
        };
    }
}

function loadModalVideo(){
    // // Get the video modal
    // var vmodal = document.getElementById("vModal");
    // // Get the image and insert it inside the modal - use its "alt" text as a caption
    // var modalVideo = document.getElementById("video01");
    // function showVideoModal(id) {
    //     var video = document.getElementById(id);
    //     video.Pause();
    //     var url = video.getElementsByTagName('source')[0].src;
    //     vmodal.style.display = "block";
    //     modalVideo.src = url;
    //     modalVideo.play();
    // };

    // // Get the <span> element that closes the modal
    // var spanV = document.getElementById("vClose");
    // // When the user clicks on <span> (x), close the modal
    // spanV.onclick = function () {
    //     vmodal.style.display = "none";
    // };
}

var discount = 0;
var coupon = "";
var charges = 0;
//Reservation
function getCharges() {
    var selectedPackage = document.getElementById("package").value;
    var schedule = SCHEDULE_FULLDAY;//document.getElementById("schedule").value;
    var totalMemeber = document.getElementById("totalVisitor").value;
    coupon = COUPON_CODES[1].name;// document.getElementById("coupon").value;
    var addtionalMemeber = 0;
    var packageName = "";
    var price = 0;
    var limit = 0;
    discount = 0;
    switch (selectedPackage) {
        case PKG_FULLDAY_OUTING.name:
            packageName = PKG_FULLDAY_OUTING.name;
            price = PKG_FULLDAY_OUTING.price;
            limit = PKG_FULLDAY_OUTING.limit;
            break;
        case PKG_MORNING_OUTING.name:
            packageName = PKG_MORNING_OUTING.name;
            price = PKG_MORNING_OUTING.price;
            limit = PKG_MORNING_OUTING.limit;
            schedule = SCHEDULE_HALFDAY;
            break;
        case PKG_EVENING_OUTING.name:
            packageName = PKG_EVENING_OUTING.name;
            price = PKG_EVENING_OUTING.price;
            limit = PKG_EVENING_OUTING.limit;
            schedule = SCHEDULE_HALFDAY;
            break;
        case PKG_PHOTO_VIDEO_SHOOT.name:
            packageName = PKG_PHOTO_VIDEO_SHOOT.name;
            price = PKG_PHOTO_VIDEO_SHOOT.price;
            limit = PKG_PHOTO_VIDEO_SHOOT.limit;
            break;
        case PKG_PARTY_EVENT.name:
            packageName = PKG_PARTY_EVENT.name;
            price = PKG_PARTY_EVENT.price;
            limit = PKG_PARTY_EVENT.price;
            break;
        case PKG_MARRAIGE_RECEPTION.name:
            packageName = PKG_MARRAIGE_RECEPTION.name;
            price = PKG_MARRAIGE_RECEPTION.price;
            limit = totalMemeber;
            break;
    }

    addtionalMemeber = totalMemeber - limit;
    if (totalMemeber > limit) {
        price = price + (addtionalMemeber * (schedule == SCHEDULE_FULLDAY ? PKG_FULLDAY_ADDITIONAL_VISITOR.price : PKG_HALFDAY_ADDITIONAL_VISITOR.price));
    }

    Array.prototype.forEach.call(COUPON_CODES, couponItem => {
        if (coupon.toUpperCase() == couponItem.name) {
            discount = couponItem.value;
            if(couponItem.type == "P")
                price = price - (price * (discount / 100));
            else
                price = price - disValue;
        }
    });

    
    var formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
    });

    var cost = formatter.format(price) + "/-.";// + discount ? discount + "% discounted" : "";
    document.getElementById("totalPrice").value = cost;
    charges = price;
    //ajaxcall(cost, discount);
};

function loadReservation(){
    var package = document.getElementById('package');
    if(package)
        package.addEventListener("change", getCharges, false);

    // var schedule = document.getElementById('schedule');
    // if(schedule)
    // schedule.addEventListener("change", getCharges, false);
    
    var visitor = document.getElementById('totalVisitor');
    if(visitor)
        visitor.addEventListener("change", getCharges, false);
    
    var coupon = document.getElementById('coupon');
    if(coupon)
        coupon.addEventListener("change", getCharges, false);
        
    var btnReserve = document.getElementById('btnReserve');
    if(btnReserve)
        btnReserve.addEventListener("click", submitInterest, false); 
}

function submitInterest() {
    var userdata = {
        package: document.getElementById("package").value,
        totalVisitor: document.getElementById("totalVisitor").value,
        adate: document.getElementById("adate").value,
        name: document.getElementById("name").value,
        email: document.getElementById("email").value,
        mobile: document.getElementById("mobile").value,
        coupon: coupon,
        cost: charges,//document.getElementById("totalPrice").value, 
        discount: discount 
    };
    createCookie("cst", userdata.cost, "1");
    createCookie("dct", userdata.discount, "1");
    const param = JSON.stringify(userdata);
    console.log(param);
    // var xhr = new XMLHttpRequest();
    // xhr.open('POST', 'form-handler.php', true);
    // xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    // xhr.onload = function (data) {
    //     // do something to response
    //     alert("Thank you for your interest. \r\n We will connect you shortly.");
    //     window.location.href ="https://ashokvatikaresort.com/";
    // };
    // xhr.onreadystatechange = function() {//Call a function when the state changes.
    //     if(xhr.readyState == 4 && xhr.status == 200) {
    //         alert("Thank you for your interest. \r\n We will connect you shortly.");
    //         window.location.href ="https://ashokvatikaresort.com/";
    //     }
    // };
    // xhr.send("x=" + param);

    $.ajax({
        type: 'post',
        data: userdata,
        success: function (response) {
            alert("Thank you for your interest. We will connect you shortly.");
        }
    });
};

// Function to create the cookie 
function createCookie(name, value, days) { 
    var expires; 

    if (days) { 
        var date = new Date(); 
        date.setTime(date.getTime() + (1 * 24 * 60 * 1000)); 
        expires = "; expires=" + date.toGMTString(); 
    } 

    else { 
        expires = ""; 
    } 

    document.cookie = escape(name) + "=" +  
    escape(value) + expires + "; path=/"; 
} 

function onLoad(){
    loadMenuBar();
    loadScroller();
    loadModalImage();
    loadModalVideo();
    loadReservation();
}

onLoad();